import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import domevents from "../../../../assets/images/blogs/pp231/domevents.png";
import screenshotshortcuts from "../../../../assets/images/blogs/pp231/screenshotshortcuts.png";
import eventlisteners from "../../../../assets/images/docs/elements/eventlisteners.png";
import bonjour from "../../../../assets/images/blogs/pp231/bonjour.png";
import marker from "../../../../assets/videos/blogs/pp231/marker.mp4";
import * as React from 'react';
export default {
  ShortCutDisplay,
  domevents,
  screenshotshortcuts,
  eventlisteners,
  bonjour,
  marker,
  React
};