import sohotrightnow from "../../../../assets/videos/blogs/pp221/so-hot-right-now.mp4";
import tabs from "../../../../assets/videos/blogs/pp221/tabs-2024.mp4";
import splitview from "../../../../assets/videos/blogs/pp221/split-view.mp4";
import astigmatism from "../../../../assets/videos/blogs/pp221/astigmatism-2024.mp4";
import latefocus from "../../../../assets/videos/blogs/pp221/late-focus.mp4";
import bloburls from "../../../../assets/images/blogs/pp221/blob.png";
import dataurls from "../../../../assets/images/blogs/pp221/data.png";
import blueskypp221 from "../../../../assets/images/blogs/pp221/bluesky.png";
import unset from "../../../../assets/images/blogs/pp221/unset.png";
import presentationalimages from "../../../../assets/images/blogs/pp221/images.png";
import websecurity from "../../../../assets/images/blogs/pp221/websecurity.png";
import colorpreviews from "../../../../assets/images/blogs/pp221/colorpreviews.png";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import * as React from 'react';
export default {
  sohotrightnow,
  tabs,
  splitview,
  astigmatism,
  latefocus,
  bloburls,
  dataurls,
  blueskypp221,
  unset,
  presentationalimages,
  websecurity,
  colorpreviews,
  ShortCutDisplay,
  React
};