import { css } from 'astroturf';
import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';

import AutosizeInput from 'react-18-input-autosize';
import TextareaAutosize from 'react-autosize-textarea';

/* TODO
  generalize main UI (not buttonbar)

*/

const styles = css`
  .offsetexample {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
    border-radius: 8px;
    overflow: hidden;
    background: #f7faff;

    @media (max-width: 40rem) {
      grid-template-columns: 1fr;
      grid-template-rows:  repeat(2, minmax(200px, 1fr));
    }
  }


  .code {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    background-color: #f7faff;

    & pre {
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      min-width: 1000px;
      background: transparent !important;
    }

    & > div > div {
      margin: 0 !important;
      width: 100%;
    }

    & .block {
      position: relative;
      overflow: hidden;
      max-height: 20rem;
      display: block;
      border-radius: 8px;
      padding: 1rem;
      background: #1e1e3f;
      color: #fff;
      border-radius: 8px;

      & > div {
        overflow: visible;
      }

      & > div,
      & textarea {
        width: calc(100% - 2rem);
        height: calc(100% - 2rem);
        position: absolute;
        inset: 1rem;
      }
    }

    & textarea {
      border: 0;
      z-index: 2;

      background: none;
      color: transparent;

      resize: none;

      direction: ltr;
      text-align: left;
      overflow: auto;
      white-space: nowrap;
      word-spacing: normal;
      word-break: normal;
      tab-size: 4;
      hyphens: none;
      font-family: 'Operator Mono', 'Fira Code', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: 0.5px;
      outline: none;
      caret-color: #fff;
    }

    & textarea::selection {
      background: #fff;
      color: #000;
    }
  }

  .block::before,
  .result::before {
    position: absolute;
    top: 0;
    z-index: 1;
    padding: 0.125rem 0.5rem 0 0.25rem;
    background: #1e1e3f;
    color: #fff;
    font-size: 0.6rem;
    line-height: 1.25;
    font-weight: 500;
    text-transform: uppercase;
    border: 2px solid #f7faff;
    right: 0;
    border-radius: 0 0 0 0.25rem;
    border-width: 0 0 2px 2px;
    pointer-events: none;
  }
  .block:global(:has(pre.language-html))::before {
    content: 'html';
  }
  .block:global(:has(pre.language-css))::before {
    content: 'css';
    top: 2px;
  }

  .result {
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    background-color: #1e1e3f;

    & iframe {
      background-color: #1e1e3f;
      border: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }

    &::before {
      z-index: 1;
      content: 'result';
    }
  }
`;

const Editor = ({
  html,
  cssCode,
  setHtml,
  setCSS,
  htmlHeight,
  cssHeight,
  htmlOffset,
  setHtmlOffset,
  cssOffset,
  setCssOffset,
  combination,
}) => {
  const iframe = useRef(null);

  useEffect(() => {
    Prism.highlightAll();
    iframe.current.srcdoc = combination;
  }, [cssCode, html]);

  return (
    <div className={styles.offsetexample}>
      <div className={styles.code}>
        <div className={styles.block} style={{ height: htmlHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${htmlOffset.x}px -${htmlOffset.y}px` }}>
            <pre className="language-html">
              <code>{html}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={html}
            onScroll={(e) => setHtmlOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setHtml(e.target.value)}
          />
        </div>
        <div className={styles.block} style={{ height: cssHeight }}>
          <div className="gatsby-highlight" style={{ translate: `-${cssOffset.x}px -${cssOffset.y}px` }}>
            <pre className="language-css">
              <code>{cssCode}</code>
            </pre>
          </div>
          <textarea
            spellCheck={false}
            value={cssCode}
            onScroll={(e) => setCssOffset({ x: e.target.scrollLeft, y: e.target.scrollTop })}
            onChange={(e) => setCSS(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.result}>
        <iframe ref={iframe} title="result" sandbox="allow-scripts" />
      </div>
    </div>
  );
};

const htmlInit = `<div class="box1">1</div>
<div class="box2">2</div>`;

const cssCodeInit = `.box1 {
  transform:
    translate(200px, 0px) rotate(45deg);
}
.box2 {
 transform:
    rotate(45deg) translate(200px, 0px);
}`;


export const OrderingExample = () => {
  const [html, setHtml] = React.useState(htmlInit);
  const [cssCode, setCSS] = React.useState(cssCodeInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        div {
          position:absolute;
          top:1rem;
          left:0;
          background: #d00;
          width: 50px;
          height: 50px;
          display: grid;
          place-items: center;
          color: #fff;
          font-weight: bold;
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={80}
        cssHeight={240}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const htmlOrdering2Init = `<div class="box1">1</div>`;

const cssOrdering2Init = `.box1 {
  transform: rotate(45deg);
}
.box1:hover {
  transform: scale(1.5);
}`;


export const Ordering2Example = () => {
  const [html, setHtml] = React.useState(htmlOrdering2Init);
  const [cssCode, setCSS] = React.useState(cssOrdering2Init);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
          place-items: center;
          display: grid;
        }

        div {
          background: #d00;
          width: 50px;
          height: 50px;
          display: grid;
          place-items: center;
          color: #fff;
          font-weight: bold;
          transition: transform 0.5s cubic-bezier(.5, -.7, .1, 1.5);
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={80}
        cssHeight={200}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};



const htmlIndividualInit = `<div class="box1">1</div>`;

const cssIndividualInit = `.box1 {
  rotate: 45deg;
}
.box1:hover {
  scale: 1.5;
}`;


export const IndividualExample = () => {
  const [html, setHtml] = React.useState(htmlIndividualInit);
  const [cssCode, setCSS] = React.useState(cssIndividualInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;

          place-items: center;
          display: grid;
        }

        div {
          background: #d00;
          width: 50px;
          height: 50px;
          display: grid;
          place-items: center;
          color: #fff;
          font-weight: bold;
          transition: scale 0.5s cubic-bezier(.5, -.7, .1, 1.5);
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={80}
        cssHeight={200}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};

const htmlAdditiveInit = `<div class="box1">1</div>
<div class="box2">2</div>
`;

const cssAdditiveInit = `.box1, .box2 {
  transform: translate(200px, 100px);
}
.box2 {
  translate: 200px 0;
}`;


export const AdditiveExample = () => {
  const [html, setHtml] = React.useState(htmlAdditiveInit);
  const [cssCode, setCSS] = React.useState(cssAdditiveInit);
  const [htmlOffset, setHtmlOffset] = React.useState({ x: 0, y: 0 });
  const [cssOffset, setCssOffset] = React.useState({ x: 0, y: 0 });

  const combination = `
  <!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        :root {
          color-scheme: dark;
        }
        body {
          background: #1e1e3f;
          display: grid;
          place-items: center;
          margin: 0;
          padding: 0;
          font-family: basic-sans, sans-serif;
          min-height: 100vh;
          font-size: 1.125em;
          line-height: 1.6;
          color:#fff;
        }

        div {
          position:absolute;
          top:1rem;
          left:0;
          background: #d00;
          width: 50px;
          height: 50px;
          display: grid;
          place-items: center;
          color: #fff;
          font-weight: bold;
          transition: scale 0.5s cubic-bezier(.5, -.7, .1, 1.5);
        }

        ${cssCode}
      </style>
    </head>
    <body>
      ${html}
    </body>
  </html>
  `;

  return (
    <div className={styles.offsetwrap}>
      <Editor
        html={html}
        cssCode={cssCode}
        setHtml={setHtml}
        setCSS={setCSS}
        htmlHeight={80}
        cssHeight={200}
        htmlOffset={htmlOffset}
        setHtmlOffset={setHtmlOffset}
        cssOffset={cssOffset}
        setCssOffset={setCssOffset}
        combination={combination}
      />
    </div>
  );
};
