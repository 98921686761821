import tree from "../../../../assets/images/docs/json/tree.png";
import raw from "../../../../assets/images/docs/json/raw.png";
import rich from "../../../../assets/images/docs/json/rich.png";
import filter from "../../../../assets/images/docs/json/filter.png";
import expand from "../../../../assets/images/docs/json/expand.png";
import * as React from 'react';
export default {
  tree,
  raw,
  rich,
  filter,
  expand,
  React
};