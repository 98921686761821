import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import muteaudio from "../../../../assets/images/docs/muteaudio.png";
import adjusttextsize from "../../../../assets/images/docs/adjusttextsize.png";
import basicauth from "../../../../assets/images/docs/basicauth.png";
import defaultsearch from "../../../../assets/images/docs/browserfeatures/default-search.png";
import managesearch from "../../../../assets/images/docs/browserfeatures/manage-search.png";
import websecurity from "../../../../assets/images/blogs/pp221/websecurity.png";
import * as React from 'react';
export default {
  ShortCutDisplay,
  muteaudio,
  adjusttextsize,
  basicauth,
  defaultsearch,
  managesearch,
  websecurity,
  React
};