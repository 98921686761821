import { Eye } from 'react-bytesize-icons';
import layouts from "../../../../assets/images/docs/layouts.png";
import quickswitcher from "../../../../assets/images/docs/quickswitcher.png";
import layoutalign from "../../../../assets/images/docs/layout-align.png";
import split from "../../../../assets/images/docs/layouts/split.png";
import ShortCutDisplay from "../../../../src/components/ShortcutDisplay";
import * as React from 'react';
export default {
  Eye,
  layouts,
  quickswitcher,
  layoutalign,
  split,
  ShortCutDisplay,
  React
};