import ShortcutDisplay from "../../../../src/components/ShortcutDisplay";
import navigator from "../../../../assets/images/docs/navigator.png";
import colorpickerfromelement from "../../../../assets/images/docs/colorpickerfromelement.mp4";
import colorpickercontrast from "../../../../assets/videos/colorpickercontrast.mp4";
import contrast from "../../../../assets/images/blogs/pp11/contrast.mp4";
import swatch from "../../../../assets/images/blogs/pp11/swatch.png";
import contrastchecker from "../../../../assets/images/blogs/webaim/colorcontrast.png";
import imghover from "../../../../assets/images/blogs/webaim/imghover.png";
import imgoutline from "../../../../assets/images/blogs/webaim/imgoutline.png";
import imgoutlinemissing from "../../../../assets/images/blogs/webaim/imgoutline-missing.png";
import missinglabelelempanel from "../../../../assets/images/blogs/webaim/missinglabelelempanel.png";
import missinglabelpeek from "../../../../assets/images/blogs/webaim/missinglabel-peek.png";
import missinganamepeek from "../../../../assets/images/blogs/webaim/missinganamepeek.png";
import missinganameoutline from "../../../../assets/images/blogs/webaim/missinganameoutline.png";
import missinganameelem from "../../../../assets/images/blogs/webaim/missinganameelem.png";
import metalang from "../../../../assets/images/blogs/webaim/metalang.png";
import metalangspecific from "../../../../assets/images/blogs/webaim/metalangspecific.png";
import metalangelem from "../../../../assets/images/blogs/webaim/metalang-elem.png";
import brokenskiplink from "../../../../assets/images/blogs/webaim/brokenskiplink.png";
import brokenskiplink2 from "../../../../assets/images/blogs/webaim/brokenskiplink2.png";
import skippedheading from "../../../../assets/images/blogs/webaim/skippedheading.png";
import longheading from "../../../../assets/images/blogs/webaim/longheading.png";
import * as React from 'react';
export default {
  ShortcutDisplay,
  navigator,
  colorpickerfromelement,
  colorpickercontrast,
  contrast,
  swatch,
  contrastchecker,
  imghover,
  imgoutline,
  imgoutlinemissing,
  missinglabelelempanel,
  missinglabelpeek,
  missinganamepeek,
  missinganameoutline,
  missinganameelem,
  metalang,
  metalangspecific,
  metalangelem,
  brokenskiplink,
  brokenskiplink2,
  skippedheading,
  longheading,
  React
};